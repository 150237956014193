import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { toast, ToastContainer } from 'react-toastify';

import Image from '../components/image';
// import { clearCart, getCart, removeFromCart, updateCart } from './cart';
import { getAlerts, getCartPage, getShippingAdmin, getTax, getWidgets } from '../../handleData';
import { useCart } from './cart-context';
import Widget from '../components/widget';

function CartView({ language, orderLimitArray }) {
    const [cartContent, setCartContent] = useState(null);
    const [formValues, setFormValues] = useState([]);
    const [items, setItems] = useState([]);
    const [subTotal, setSubTotal] = useState(0);
    const [shippingPrice, setShippingPrice] = useState(0);
    const [taxRate, setTaxRate] = useState(0);
    const [taxAmount, setTaxAmount] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);

    const { cart, updateCart, removeFromCart, clearCart, setShippingInfo, setTotalPriceContext } = useCart();

    const [courier, setCourier] = useState('');
    const [purolatorData, setPurolatorData] = useState([]);
    const [purolatorAdmin, setPurolatorAdmin] = useState(null);
    const [purolatorUrl, setPurolatorUrl] = useState(null);
    const [canadaPostData, setCanadaPostData] = useState([]);
    const [canadaPostUrl, setCanadaPostUrl] = useState(null);
    const [canadaPostAdmin, setCanadaPostAdmin] = useState(null);
    const [shippingUrl, setShippingUrl] = useState(null);


    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [address2, setAddress2] = useState('');
    const [country] = useState('CA');
    const [province, setProvince] = useState('');
    const [city, setCity] = useState('');
    const [postal, setPostal] = useState('');
    const [totalWeight, setTotalWeight] = useState(0);

    const form = useRef(null);
    // const input = useRef(null);
    const button = useRef(null);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isPurchaseDisabled, setIsPurchasedDisabled] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [alerts, setAlerts] = useState(null);


    // const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
        setItems(cart);
    }, [cart]);


    useEffect(() => {
        if (window.location.hostname === 'localhost') {
            setPurolatorUrl('http://localhost:80/purolator/GetQuickEstimate.php');
            setCanadaPostUrl('http://localhost:80/canada-post/GetRates.php');
            setShippingUrl('http://localhost:3002/index.php');
        } else {
            setPurolatorUrl(`/shipping/purolator/index.php`);
            setCanadaPostUrl(`/shipping/canada-post/index.php`);
        }
    }, []);

    useEffect(() => {
        if (language) {
            getCartPage(language).then(result => {
                setCartContent(result);
            });
        }
    }, [language]);

    useEffect(() => {
        if (language) {
            getWidgets("form", language).then(result => {
                setFormValues(result[0]);
            });
        }
    }, [language]);

    useEffect(() => {
        if (language) {
            getAlerts(language).then(result => {
                setAlerts(result);
            });
        }
    }, [language]);

    useEffect(() => {
        // Calculate total price whenever cart items change
        let subTotal = 0;
        let totalPreTax = 0;
        let shippingCost = 0;
        let tax = 0;
        let total = 0;
        Object.values(items).map((item) => {
            subTotal += item.price * item.Quantity;
            return subTotal;
        });
        subTotal = subTotal.toFixed(2);
        subTotal = parseFloat(subTotal);
        setSubTotal(subTotal);
        if (shippingPrice && shippingPrice > 0 && taxRate > 0) {
            shippingCost = shippingCost.toFixed(2);
            shippingCost = parseFloat(shippingPrice);
            totalPreTax = shippingCost + subTotal;
            totalPreTax = parseFloat(totalPreTax);
            totalPreTax = totalPreTax.toFixed(2);
            tax = taxRate * totalPreTax;
            tax = tax.toFixed(2);
            total = parseFloat(tax) + parseFloat(subTotal) + parseFloat(shippingCost);
            total = parseFloat(total);
            total = total.toFixed(2);

            setTaxAmount(parseFloat(tax));
            setTotalPrice(total);
            setTotalPriceContext(total);
        }

    }, [items, shippingPrice, taxRate]);

    const removeFromCartBtn = (itemId) => {
        removeFromCart(itemId);
        // window.location.reload();
    }

    const clearCartBtn = () => {
        clearCart()
        // window.location.reload();
    }

    const setQuantity = (itemId, newQuantity, stock) => {
        if (items && alerts) {
            const updatedItems = { ...items };

            if (stock <= 0 || newQuantity > orderLimitArray.length) {
                toast.error(alerts[0][0].LimitExceeded);
                return;
            } else if (newQuantity > stock) {
                toast.error(alerts[0][0].LimitExceedsStock);
                return;
            } else if (newQuantity === orderLimitArray.length || newQuantity === stock) {
                toast.error(alerts[0][0].LimitReached);
                newQuantity = Math.max(newQuantity, 1);
                updatedItems[itemId].Quantity = newQuantity;
                setItems(updatedItems);
                updateCart(updatedItems);
            } else {
                newQuantity = Math.max(newQuantity, 1);
                updatedItems[itemId].Quantity = newQuantity;
                setItems(updatedItems);
                updateCart(updatedItems);
            }
        }
    };

    // const handleShipping = async (event) => {
    //     event.preventDefault();
    //     sendShipping(name, email, address, address2, city, country, province, postal, totalPrice);
    //     button.current.disabled = true;
    //     setIsDisabled(true);
    //     toast.success("Shipping successfully calculated.");
    // };

    const handleCheckout = (e) => {
        if (purolatorData && canadaPostData && Object.keys(purolatorData).length > 0 && Object.keys(canadaPostData).length > 0 ) {
            if (!isPurchaseDisabled && alerts) {
                e.preventDefault();
                toast.error(alerts[2][0].ShippingCantProceed);
            }
        } else {
            if (!isPurchaseDisabled && alerts) {
                e.preventDefault();
                toast.error(alerts[2][0].ShippingEmpty);
            }
        }
    };

    const provincesFull = [
        "Alberta",
        "British Columbia",
        "Manitoba",
        "New Brunswick",
        "Newfoundland and Labrador",
        "Northwest Territories",
        "Nova Scotia",
        "Nunavut",
        "Ontario",
        "Prince Edward Island",
        "Quebec",
        "Saskatchewan",
        "Yukon",
    ];

    const provincesAbbreviation = [
        "AB",
        "BC",
        "MB",
        "NB",
        "NL",
        "NT",
        "NS",
        "NU",
        "ON",
        "PE",
        "QC",
        "SK",
        "YT",
    ];

    useEffect(() => {
        getShippingAdmin().then(result => {
            setCanadaPostAdmin(result[0]);
            setPurolatorAdmin(result[1]);
        })
    }, []);

    const handlePurolator = async (event) => {
        event.preventDefault();
        setIsProcessing(true);
        const form = $(event.target);
        const formData = form.serializeArray(); // Serialize form data as an array
        const formDataObject = {};

        // Convert the serialized array into an object
        formData.forEach((field) => {
            formDataObject[field.name] = field.value;
            if (field.name === 'postal') {
                const upperPostal = field.value.toUpperCase();
                formDataObject[field.name] = upperPostal;
            }
        });
        let purolatorOption;
        let canadaPostOption;

        if (purolatorAdmin && canadaPostAdmin) {
            purolatorOption = purolatorAdmin[0][`${purolatorAdmin[0].Selection}`];
            canadaPostOption = canadaPostAdmin[0][`${canadaPostAdmin[0].Selection}`];
        }

        try {
            if (purolatorUrl && canadaPostUrl && alerts) {
                // const response = await axios.post(purolatorUrl, formDataObject);
                const purolatorResponse = await fetch(purolatorUrl, {
                    "method": "POST",
                    "headers": {
                        "Content-Type": "application/json; charset=utf-8"
                    },
                    "body": JSON.stringify(formDataObject)
                });
                const dataPurolator = await purolatorResponse.json();
                const canadaPostResponse = await fetch(canadaPostUrl, {
                    "method": "POST",
                    "headers": {
                        "Content-Type": "application/json; charset=utf-8"
                    },
                    "body": JSON.stringify(formDataObject)
                });

                const dataCanadaPost = await canadaPostResponse.json();
                // const shippingResonse = await fetch(shippingUrl, {
                //     "method": "POST",
                //     "headers": {
                //         "Content-Type": "application/json; charset=utf-8",
                //     },
                //     "body": JSON.stringify(formDataObject),
                //     // "mode": 'no-cors'
                // });
                // const dataShipping = await shippingResonse.json();
                // console.log(dataShipping);
                if (dataPurolator.Error) {
                    toast.error(dataPurolator.Error.Description);
                    setIsProcessing(false);
                } else if (dataPurolator['ShippingPrices'][`${purolatorOption}`] && dataCanadaPost['ShippingPrices'][`${canadaPostOption}`]) {
                    setPurolatorData(dataPurolator);
                    setCanadaPostData(dataCanadaPost);

                    button.current.disabled = true;
                    toast.success(alerts[2][0].ShippingCalculated);
                    setIsProcessing(false);
                    setIsDisabled(true);
                } else {
                    toast.error(alerts[2][0].ShippingFail);
                    setIsProcessing(false);
                }
            }

        } catch (error) {
            toast.error(alerts[2][0].ShippingFail);
            console.error('Error:', error);
            setIsProcessing(false);
        }
    }


    useEffect(() => {
        if (isDisabled) {
            if (form.current) {
                const formInputs = form.current.querySelectorAll('input');
                const formSelect = form.current.querySelector('select');
                const selects = document.querySelectorAll('.cart-buttons-container select');
                const buttons = document.querySelectorAll('.cart-buttons-container button');

                formInputs.forEach(input => {
                    input.disabled = true;
                });

                buttons.forEach(button => {
                    button.disabled = true;
                });

                selects.forEach(select => {
                    select.disabled = true;
                });
                formSelect.disabled = true;
            }
        }
    }, [isDisabled]);


    const handleShippingOption = (option) => {
        const radio = document.querySelector('input#canada-post');
        const radio2 = document.querySelector('input#purolator');
        let purolatorOption;
        let canadaPostOption;

        if (purolatorAdmin && canadaPostAdmin) {
            purolatorOption = purolatorAdmin[0][`${purolatorAdmin[0].Selection}`];
            canadaPostOption = canadaPostAdmin[0][`${canadaPostAdmin[0].Selection}`];
        }

        if (option["ShippingPrices"]) {
            if (option["ShippingPrices"][`${canadaPostOption}`]) {
                radio.checked = true;
                radio2.checked = false;

                setShippingPrice(option["ShippingPrices"][`${canadaPostOption}`]);
                setCourier("Canada Post");

                getTax(province).then(result => {
                    setTaxRate(result);
                });
                setIsPurchasedDisabled(true);
            }
        }
        if (option['ShippingPrices']) {
            if (option['ShippingPrices'][`${purolatorOption}`]) {
                radio.checked = false;
                radio2.checked = true;

                setShippingPrice(option['ShippingPrices'][`${purolatorOption}`]);
                setCourier("Purolator");

                getTax(province).then(result => {
                    setTaxRate(result);
                });
                setIsPurchasedDisabled(true);
            }
        }
    }

    useEffect(() => {
        if (courier && shippingPrice) {
            // let price = purolatorData;
            // price = parseFloat(price);
            // price = price.toFixed(2);
            // setShippingPrice(price);
            getTax(province).then(result => {
                setTaxRate(result);
            })
            if (taxAmount > 0 && subTotal > 0) {
                setShippingInfo(name, phone, email, address, address2, city, country, province, postal, courier, shippingPrice, taxAmount, subTotal);
            }
        }
    }, [name, phone, email, address, address2, city, country, province, postal, taxAmount, courier, shippingPrice, subTotal]);


    // const createStockArray = (stock) => {
    //     const array = [];

    //     for (let i = 1; i <= stock; i++) {
    //         array.push(i);
    //     }
    //     return array;
    // }

    useEffect(() => {
        let calculatedTotalWeight = 0;
        Object.keys(items).forEach((part) => {
            const item = items[part];
            calculatedTotalWeight += item.weight * item.Quantity;
        });

        if (calculatedTotalWeight <= 0) {
            setTotalWeight(1);
        } else {
            setTotalWeight(calculatedTotalWeight);
        }
    }, [items]);

    return (
        <main className='cart-main'>
            <ToastContainer />
            <div className="cart-container flex">
                <div className="cart-items content">
                    {cartContent &&
                        <div className="flex">
                            {cartContent[0].map((content, index) => (
                                <React.Fragment key={index}>
                                    <h2>{content.Heading}</h2>
                                    <Link to={content.ButtonLink} title={content.ButtonText}>{content.ButtonText}</Link>
                                </React.Fragment>
                            ))}
                        </div>
                    }
                    {items && cartContent && Object.keys(items).length > 0 ? (
                        <>
                            {Object.values(items).map((item, index) => {
                                // const stockArray = createStockArray(item.Stock);
                                return (
                                    <React.Fragment key={index}>
                                        <div key={item.Id} className='cart-item flex'>
                                            <div className="cart-image">
                                                <Image imageName={item.image} imageTitle={item.desc} lazyOption={'eager'} />
                                            </div>
                                            <div className="cart-details">
                                                <div className="flex">
                                                    <h3>{item.title}</h3>
                                                    <h3><strong>${item.price.toFixed(2)}</strong></h3>
                                                </div>
                                                <div className="flex">
                                                    <div>
                                                        <p>{item.subcat.substring(2)}</p>
                                                        <div className="stock-container">
                                                            <p>{cartContent[0][0].Stock}: {item.Stock}</p>
                                                        </div>
                                                    </div>

                                                    <div className='cart-buttons-container flex'>
                                                        <div className="cart-buttons">
                                                            <button onClick={() => setQuantity(item.part, item.Quantity + 1, item.Stock)} disabled={item.Quantity === orderLimitArray.length || item.Quantity === item.Stock} className='button'>+</button>
                                                            <button onClick={() => setQuantity(item.part, item.Quantity - 1, item.Stock)} disabled={item.Quantity === 1} className='button'>-</button>
                                                        </div>
                                                        <div className='cart-button-input'>
                                                            <input
                                                                type="number"
                                                                value={parseInt(item.Quantity)} // Convert to integer using parseInt
                                                                onChange={(event) => setQuantity(item.part, parseInt(event.target.value), item.Stock)}
                                                                onBlur={(event) => {
                                                                    if (event.target.value === '') {
                                                                        setQuantity(item.part, 1, item.Stock);
                                                                    }
                                                                }}
                                                            />
                                                            {/* <select value={item.Quantity} onChange={(event) => setQuantity(item.part, event.target.value, item.Stock)}>
                                                                {stockArray.length <= orderLimitArray.length && (
                                                                    stockArray.map((value, index) => (
                                                                        <option key={index} value={value}>{value}</option>
                                                                    ))
                                                                )}

                                                                {orderLimitArray.length < stockArray.length && (
                                                                    orderLimitArray.map((value, index) => (
                                                                        <option key={index} value={value}>{value}</option>
                                                                    ))
                                                                )}
                                                            </select> */}
                                                        </div>
                                                        <button onClick={() => removeFromCartBtn(item.part)} title='Remove Item' className='button'><i className="fa-solid fa-trash-can"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                );
                            })}
                            {cartContent && cartContent[0].map((content, index) => (
                                <React.Fragment key={index}>
                                    <button onClick={() => clearCartBtn()} className='button'>{content.ClearButton} <i className="fa-solid fa-trash-can"></i></button>
                                </React.Fragment>
                            ))}
                        </>
                    ) : (
                        <div className='empty-cart'>
                            {cartContent && cartContent[0].map((content, index) => (
                                <React.Fragment key={index}>
                                    <h3>{content.EmptyCart}</h3>
                                </React.Fragment>
                            ))}
                        </div>
                    )}

                </div>
                <div className="cart-summary">
                    <div className="cart-summary-card">
                        {cartContent && cartContent[1].map((content, contentIndex) => (
                            <React.Fragment key={contentIndex}>
                                <h2>{content.Heading}</h2>
                            </React.Fragment>
                        ))}

                        {items && Object.keys(items).length > 0 ? (
                            <>
                                {cartContent && cartContent[1].map((content, contentIndex) => (
                                    <React.Fragment key={contentIndex}>
                                        <form className="shipping-form" onSubmit={(event) => handlePurolator(event)} /*action={purolatorUrl ? purolatorUrl : ""}*/ ref={form}>
                                            {formValues &&
                                                <>
                                                    <div className="flex">
                                                        <input type='text' name='name' id='name' placeholder={formValues.Name} onChange={(event) => setName(event.target.value)} required />
                                                        <input type='tel' name='phone' id='phone' placeholder={formValues.Phone} onChange={(event) => setPhone(event.target.value)} maxLength="10" pattern='[0-9]{10}' inputMode='numeric' onKeyDown={(event) => {
                                                            const key = event.key;
                                                            if (!(key === 'Backspace' || key === 'Tab') && !/^[0-9]+$/.test(key)) {
                                                                event.preventDefault();
                                                            }
                                                        }} required />
                                                    </div>

                                                    <div className="flex">
                                                        <input type='email' name='email' id='email' placeholder={formValues.Email} onChange={(event) => setEmail(event.target.value)} required />
                                                    </div>

                                                    <div className="flex">
                                                        <input type='text' name='address' id='address' placeholder={formValues.Address} onChange={(event) => setAddress(event.target.value)} required />
                                                        <input type='text' name='address2' id='address2' placeholder={formValues.Address2} onChange={(event) => setAddress2(event.target.value)} />
                                                    </div>

                                                    <div className="flex">
                                                        <input type='text' name='city' id='city' placeholder={formValues.City} onChange={(event) => setCity(event.target.value)} required />
                                                        <input type='text' name='postal' id='postal' pattern='[A-Za-z][0-9][A-Za-z][0-9][A-Za-z][0-9]' placeholder={formValues.PostalCode} onKeyDown={(event) => {
                                                            const key = event.key;
                                                            if (!(/[a-zA-Z0-9]/.test(key))) {
                                                                event.preventDefault();
                                                            }
                                                            if (key === ' ') {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                            onPaste={(event) => {
                                                                event.preventDefault(); // Prevent the default paste behavior
                                                                const clipboardData = event.clipboardData;
                                                                const pastedText = clipboardData.getData('text');
                                                                const newValue = pastedText.replace(/\s/g, ''); // Remove all spaces from the pasted text
                                                                // Update the state with the new value
                                                                setPostal(newValue.toUpperCase());
                                                            }}
                                                            onInput={(event) => {
                                                                const newValue = event.target.value.replace(/\s/g, '');
                                                                setPostal(newValue.toUpperCase());
                                                                if (newValue) {
                                                                    event.target.maxLength = 6;
                                                                } else {
                                                                    event.target.maxLength = 7;
                                                                }
                                                            }}
                                                            value={postal}
                                                            style={{ textTransform: 'uppercase' }} maxLength={7} required />
                                                    </div>

                                                    <div className="flex">
                                                        <select name='province' id="province" value={province} onChange={(event) => setProvince(event.target.value)} required>
                                                            {provincesFull.map((province, index) => (
                                                                <option key={index} value={provincesAbbreviation[index]}>
                                                                    {province} - {provincesAbbreviation[index]}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <input type='text' name='displayCountry' id='displayCountry' value="Canada" required disabled placeholder='Canada' />
                                                    </div>


                                                    {totalWeight > 0 &&
                                                        <input type='hidden' name="weight" id="weight" value={totalWeight} />
                                                    }
                                                    {taxAmount > 0 &&
                                                        <input type='hidden' name="taxAmount" id="taxAmount" value={taxAmount} />
                                                    }
                                                    {country &&
                                                        <input type='hidden' name='country' id='country' value={country} />
                                                    }
                                                    {courier &&
                                                        <input type='hidden' name='courier' id='courier' value={courier} />
                                                    }
                                                    <div>
                                                        <button className='button' id='submit' ref={button}>{content.ShippingButton} <span>{isProcessing ? <i className="fa-solid fa-spinner fa-spin"></i> : ""}</span></button>
                                                    </div>
                                                </>
                                            }
                                        </form>
                                        <div>
                                            {canadaPostData && Object.keys(canadaPostData).length > 0 && purolatorData && Object.keys(purolatorData).length > 0 && canadaPostAdmin && purolatorAdmin &&
                                                <div className="shipping-options-container">
                                                    <div className="shipping-options flex">
                                                        <button className='shipping-options-card flex' onClick={() => handleShippingOption(canadaPostData)}>
                                                            <div className='flex'>
                                                                <div className='radio-container'>
                                                                    <input type='radio' id='canada-post' name='canada-post' />
                                                                </div>
                                                                <div>
                                                                    <p className='courier'>{canadaPostAdmin[0].Heading}</p>
                                                                    <p className='delivery'><small>{parseInt(canadaPostData['ShippingDays'][canadaPostAdmin[0][`${canadaPostAdmin[0].Selection}`]]) + canadaPostAdmin[0].OffsetDays} business day(s)</small></p>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <p className='shipping-option'><strong>${parseFloat(canadaPostData['ShippingPrices'][canadaPostAdmin[0][`${canadaPostAdmin[0].Selection}`]]).toFixed(2)}</strong></p>
                                                            </div>
                                                        </button>
                                                        <button className='shipping-options-card flex' onClick={() => handleShippingOption(purolatorData)}>
                                                            <div className='flex'>
                                                                <div className='radio-container'>
                                                                    <input type='radio' id='purolator' name='purolator' />
                                                                </div>
                                                                <div>
                                                                    <p className='courier'>{purolatorAdmin[0].Heading} <i className="fa-solid fa-truck-fast"></i></p>
                                                                    <p className='delivery'><small>{parseInt(purolatorData['ShippingDays'][purolatorAdmin[0][`${purolatorAdmin[0].Selection}`]]) + purolatorAdmin[0].OffsetDays} business day(s)</small></p>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <p className='shipping-option'><strong>${parseFloat(purolatorData['ShippingPrices'][purolatorAdmin[0][`${purolatorAdmin[0].Selection}`]]).toFixed(2)}</strong></p>
                                                            </div>
                                                        </button>
                                                    </div>
                                                </div>
                                            }
                                            <p>{content.SubTotal} ${subTotal.toFixed(2)}</p>
                                            {shippingPrice && shippingPrice > 0 ? (
                                                <p>{content.Shipping} ${shippingPrice}</p>
                                            ) : (
                                                <p>{content.Shipping} $0.00</p>
                                            )}
                                            <p>{content.Tax} ${taxAmount.toFixed(2)}</p>
                                            <p>{content.Total} ${parseFloat(totalPrice).toFixed(2)}</p>
                                        </div>
                                        <Link to={content.ButtonLink} onClick={handleCheckout} className={`button ${isPurchaseDisabled ? '' : 'disabled'}`}>{content.ButtonText}</Link>
                                    </React.Fragment>
                                ))}
                            </>
                        ) : (
                            <div className='empty-cart flex'>
                                {cartContent && cartContent[0].map((content, index) => (
                                    <React.Fragment key={index}>
                                        <p>{content.EmptyCart}</p>
                                        <Link to={content.ButtonLink} title={content.ButtonText}>{content.ButtonText}</Link>
                                    </React.Fragment>
                                ))}
                            </div>
                        )}

                    </div>
                </div>
            </div>
            <Widget type={'contact'} language={language} />
        </main>
    );
}

export default CartView;