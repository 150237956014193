import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { ToastContainer } from 'react-toastify';

import Image from '../components/image';
import { getShop, getProducts } from "../../handleData";
import Widget from "../components/widget";

function Shop({ host, language, dataCode }) {
    const [intro, setIntro] = useState([]);

    const [productData, setProductData] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [isSearch, setIsSearch] = useState(false);
    const [subCategories, setSubCategories] = useState([]);
    const [categories, setCategories] = useState([]);
    const [filteredSubCategories, setFilteredSubCategories] = useState([]);

    const welcomeElement = useRef(null);
    const categoriesElement = useRef(null);
    const filteredElement = useRef(null);
    const categoryLinks = useRef(null);
    const openSearchBtn = useRef(null);
    const closeSearchBtn = useRef(null);
    const clearSearchBtn = useRef(null);
    const searchInput = useRef(null);
    const searchTitle = useRef(null);

    // const [stock, setStock] = useState(0);

    // const { addToCart } = useCart();
    useEffect(() => {
        setTimeout(() => {
            if (welcomeElement.current) {
                welcomeElement.current.scrollIntoView({
                    behavior: 'smooth'
                });
            }
        }, 0);

    }, [welcomeElement.current]);

    useEffect(() => {
        if (host && language) {
            getShop(host, language).then(result => {
                setIntro(result[0]);
            });
        }
    }, [host, language]);

    useEffect(() => {
        if (dataCode) {
            getProducts(dataCode).then(result => {
                setProductData(result[0]);
                setFilteredProducts(result[0]);
                setSubCategories(result[1]);
                setFilteredSubCategories(result[1]);
                setCategories(result[2]);
            })
        }
    }, [dataCode]);

    // const checkStock = (index, product) => {
    //     const partNumber = productData[index]["part"];
    //     getStock(partNumber).then(result => {
    //         let requestId = result.requestId;
    //         if (result.stockResult[requestId].GETSTOCK <= 0) {
    //             setStock(0);
    //             toast.error("Item not in stock, contact us for more information", {
    //                 position: toast.POSITION.TOP_LEFT,
    //             });
    //         } else {
    //             const parsedStock = parseInt(result.stockResult[requestId].GETSTOCK);
    //             setStock(parsedStock);
    //             addToCart(product, parsedStock);
    //             toast.success("Item added to cart!", {
    //                 position: toast.POSITION.TOP_LEFT,
    //             });
    //         }
    //     });
    // }

    useEffect(() => {
        const handleScroll = () => {
            if (categoriesElement.current) {
                if (categoriesElement.current.getBoundingClientRect().top === 0) {
                    categoriesElement.current.classList.add('categories-sticky');
                } else {
                    categoriesElement.current.classList.remove('categories-sticky');
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleSearch = (event) => {
        const search = event.target.value.toLowerCase();
        if (productData && Object.keys(productData).length > 0) {
            const filteredSearch = productData.filter(product => product.desc.toLowerCase().includes(search) || product.part.toLowerCase().includes(search));

            setFilteredProducts(filteredSearch);
        }
    }

    const clearSearch = () => {
        const inputs = document.querySelectorAll('.search-bar input');
        inputs.forEach((input) => {
            input.value = '';
        });

        setFilteredProducts(productData);
    }

    const openSearchContainer = () => {
        setIsSearch(true);
        setFilteredSubCategories(subCategories);

        const input = document.querySelector('.search-bar input');
        const searchContainer = document.querySelector('.search-bar');
        const categories = categoriesElement.current.querySelector('.categories-list');

        input.focus();
        if (searchTitle.current) {
            searchTitle.current.scrollIntoView({ behavior: 'smooth' });
        }
        categories.classList.add('hide-search');
        searchContainer.classList.remove('hide-search');
        // searchContainer.current.classList.add('open-search', 'animate__zoomIn');
        // closeSearchBtn.current.style.display = "block";
        // clearSearchBtn.current.style.display = "block";
    }

    const closeSearchContainer = () => {
        setIsSearch(false);
        setFilteredSubCategories(subCategories);
        const searchContainer = document.querySelector('.search-bar');
        const categories = categoriesElement.current.querySelector('.categories-list');
        categories.classList.remove('hide-search');
        searchContainer.classList.add('hide-search');

        // searchContainer.current.classList.remove('open-search', 'animate__zoomOut');
        // categoryLinks.current.style.display = 'flex';
        // closeSearchBtn.current.style.display = "none";
        // clearSearchBtn.current.style.display = "none";
    }

    // useEffect(() => {
    //     const searchContainer = document.querySelector('.search-bar');
    //     const categories = categoriesElement.current.querySelector('.categories-list');

    //     if (isSearch) {
    //         categories.classList.add('hide-search');
    //         searchContainer.classList.remove('hide-search');
    //     } else {
    //         categories.classList.remove('hide-search');
    //         searchContainer.classList.add('hide-search');
    //     }

    // }, [isSearch]);

    const filterAll = (event) => {
        const element = event.target;
        const catBtns = document.querySelectorAll('.cat-btn');
        catBtns.forEach((button => {
            if (button === element) {
                element.classList.add('selected-category');
            } else {
                button.classList.remove('selected-category');
            }
        }));

        setFilteredSubCategories(subCategories);

    }

    const filterCategory = (event, category) => {
        const filteredProduct = productData.filter(product => product.catagory.toLowerCase().includes(category.toLowerCase()));
        const filteredCat = Array.from(new Set(filteredProduct.map(product => product.subcat.substring(2))));

        const element = event.target;
        const catBtns = document.querySelectorAll('.cat-btn');
        catBtns.forEach((button => {
            if (button === element) {
                element.classList.add('selected-category');
            } else {
                button.classList.remove('selected-category');
            }
        }));

        setFilteredProducts(filteredProduct);
        setFilteredSubCategories(filteredCat);
    }


    const openBtn = useRef(null);
    const closeBtn = useRef(null);
    const mobileNav = useRef(null);

    useEffect(() => {
        const handleClickOutsideMobile = (event) => {
            if (mobileNav.current && !mobileNav.current.contains(event.target)) {
                // Click occurred outside of cart widget, so close the cart widget
                closeMobile();
            }
        };

        document.addEventListener('mousedown', handleClickOutsideMobile);
        return () => {
            document.removeEventListener('mousedown', handleClickOutsideMobile);
        };
    }, []);

    const openMobile = () => {
        if (openBtn.current && closeBtn.current && mobileNav.current) {
            closeBtn.current.style.display = 'block';
            mobileNav.current.style.display = 'block';
            mobileNav.current.classList.add('animate__slideInRight');
            mobileNav.current.classList.remove('animate__slideOutUp');
            setIsSearch(false);
        }
    }

    const closeMobile = () => {
        if (openBtn.current && closeBtn.current && mobileNav.current) {
            closeBtn.current.style.display = 'none';
            mobileNav.current.style.display = 'none';
            mobileNav.current.classList.remove('animate__slideInRight');
            mobileNav.current.classList.add('animate__slideOutUp');
        }
    }


    return (
        <main>
            <ToastContainer />
            <Widget type={'masthead'} host={host} language={language} />

            <section className="content flex" ref={welcomeElement}>
                {intro.map((intro, index) => (
                    <React.Fragment key={index}>
                        <div className="welcome-img">
                            <Image host={host} imageName={intro.Image} imageTitle={intro.Heading} />
                        </div>
                        <div className="welcome-content">
                            <h2>{intro.Heading}</h2>
                            <p>{intro.Text}</p>
                            <p>{intro.Text2}</p>
                            <Link to={'/contact'} className="btn">{intro.ButtonText}</Link>
                        </div>
                    </React.Fragment>
                ))}
            </section>

            <div className="content categories" id="categories" ref={categoriesElement}>
                <div className="categories-list" >
                    <div className="categories-array-container flex">
                        <div>
                            {intro.map((intro, index) => (
                                <React.Fragment key={index}>
                                    <h2>{intro.Heading2}</h2>
                                </React.Fragment>
                            ))}
                        </div>
                        {categories.length > 1 &&
                            <div className="categories-array flex">
                                <button onClick={filterAll} className="btn cat-btn selected-category">View All</button>
                                {categories.map((category, index) => (
                                    <React.Fragment key={index}>
                                        <button onClick={(event) => filterCategory(event, category)} className="btn cat-btn">{category}</button>
                                    </React.Fragment>
                                ))}
                            </div>
                        }
                    </div>
                    <div className="flex">
                        <div className="main-categories flex" ref={categoryLinks}>
                            {filteredSubCategories.map((category, index) => (
                                <React.Fragment key={index}>
                                    <HashLink to={`#${category}`} title={category} className="btn">{category}</HashLink>
                                </React.Fragment>
                            ))}
                        </div>
                        <i className="fa-solid fa-magnifying-glass search-icon" ref={openSearchBtn} onClick={openSearchContainer} title="Search Product"></i>
                    </div>
                </div>

                <div className="main-search search-bar flex hide-search">
                    <i className="fa-solid fa-magnifying-glass" ref={openSearchBtn} onClick={openSearchContainer} title="Search Product"></i>
                    <input type="text" name="search" id="search" onChange={handleSearch} placeholder="Search" rel={searchInput} />
                    <div className="flex">
                        <i className="fa-solid fa-xmark" ref={clearSearchBtn} onClick={clearSearch} title="Clear Search Bar"></i>
                        <i className="fa-solid fa-angle-right" ref={closeSearchBtn} onClick={closeSearchContainer} title="Close Search Bar"></i>
                    </div>
                </div>

                {/* mobile categories */}
                <div className="mobile-categories-container flex">
                    <div>
                        <p><strong>View Categories</strong></p>
                    </div>
                    <div className="search-bar flex">
                        <i className="fa-solid fa-magnifying-glass" title="Search Product"></i>
                        <input type="text" name="search" id="search" onFocus={() => setIsSearch(true)} onChange={handleSearch} placeholder="Search" rel={searchInput} />
                        <i className="fa-solid fa-xmark" ref={clearSearchBtn} onClick={clearSearch} title="Clear Search Bar"></i>
                    </div>
                    <div>
                        <button className="btn mobile-button" onClick={openMobile} ref={openBtn}><i className="fa-solid fa-bars"></i></button>
                    </div>
                </div>

            </div>

            <div className={`mobile-nav animate__animated`} ref={mobileNav} id="categories">
                <button className="mobile-button-close" onClick={closeMobile} ref={closeBtn}><i className="fa-solid fa-xmark"></i></button>
                <ul className="flex">
                    {subCategories.map((category, index) => (
                        <li key={index}><HashLink to={`#${category}`} title={category}>{category}</HashLink></li>
                    ))}
                </ul>
            </div>

            {isSearch ? (
                <section className="content filtered" id="shop" ref={filteredElement}>
                    <div className="categories-title" ref={searchTitle}>
                        {intro.map((intro, index) => (
                            <React.Fragment key={index} >
                                <h2>{intro.Searching}</h2>
                            </React.Fragment>
                        ))}
                    </div>
                    <div className="card-grid-container">
                        {filteredProducts && Object.keys(filteredProducts).length > 0 ? (
                            filteredProducts.map((products, index) => (
                                <React.Fragment key={index}>
                                    <HashLink to={`/product/${products.part}#product`} className="card">
                                        <div className="card-image">
                                            <Image imageName={products.image} imageTitle={products.desc} />
                                        </div>
                                        <div className="card-content">
                                            <div>
                                                <p>{products.subcat.substring(2)}</p>
                                                <h4>{products.title}</h4>
                                            </div>
                                            <div>
                                                {intro.map((intro, introIndex) => {
                                                    <React.Fragment key={introIndex}>
                                                        {products.price > 0 ? (
                                                            <h4><strong>${products.price.toFixed(2)}</strong></h4>
                                                        ) : (
                                                            <h4><strong>{intro.ContactPrice}</strong></h4>
                                                        )}
                                                    </React.Fragment>
                                                })}
                                                {/* <button onClick={() => checkStock(index, products)} title="Add To Cart"><i className="fa-solid fa-cart-plus"></i></button> */}
                                            </div>
                                        </div>
                                    </HashLink>
                                </React.Fragment>
                            ))
                        ) : (
                            <div className="no-matches">
                                {intro.map((intro, index) => (
                                    <React.Fragment key={index} >
                                        <h3><i className="fa-solid fa-circle-info"></i> {intro.NoSearch}</h3>
                                    </React.Fragment>
                                ))}
                            </div>
                        )}

                    </div>
                </section>
            ) : (
                <div>
                    {subCategories.map((category, index) => {
                        const subcatProducts = productData.filter(product => product.subcat.substring(2) === category);
                        return (
                            <React.Fragment key={index}>
                                <section className="content" id="shop">
                                    <div className="categories-title" id={category}>
                                        <h2>{category}</h2>
                                    </div>

                                    <div className="card-grid-container">
                                        {subcatProducts.map((products, productIndex) => (
                                            <React.Fragment key={productIndex}>
                                                <HashLink to={`/product/${products.part}#product`} className="card">
                                                    <div className="card-image">
                                                        <Image imageName={products.image} imageTitle={products.desc} />
                                                    </div>
                                                    <div className="card-content">
                                                        <div>
                                                            <p><small>{products.subcat.substring(2)}</small></p>
                                                            <h4>{products.title}</h4>
                                                        </div>
                                                        <div>
                                                            {intro && intro.map((intro, introIndex) => {
                                                                return (
                                                                    <React.Fragment key={introIndex}>
                                                                        {products.price > 0 ? (
                                                                            <h4><strong>${products.price.toFixed(2)}</strong></h4>
                                                                        ) : (
                                                                            <h4><strong>{intro.ContactPrice}</strong></h4>
                                                                        )}
                                                                    </React.Fragment>
                                                                );
                                                            })}
                                                            {/* <button onClick={() => checkStock(index, products)} title="Add To Cart"><i className="fa-solid fa-cart-plus"></i></button> */}
                                                        </div>
                                                    </div>
                                                </HashLink>
                                            </React.Fragment>
                                        ))}
                                    </div>
                                </section>
                            </React.Fragment>
                        );
                    })}
                </div>
            )
            }

            <Widget type={'contact'} language={language} />
        </main >
    );
}

export default Shop;